import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import withI18next from '../components/withI18Next';
import QmcloudsOgImg from '../images/qmclouds-og-img.png';
import DeviationReporting from '../pages-partials/article-deviation-reporting/DeviationReporting';
import DeviationReportingArticleHero from '../pages-partials/article-deviation-reporting/DeviationReportingArticleHero';

const DeviationReportingPage = ({ pageContext: { locale }, t }: any) => (
  <Layout locale={locale}>
    <SEO
      title={t('DeviationReportingSeoTitle')}
      description={t('DeviationReportingSeoDescription')}
      pageImage={QmcloudsOgImg}
      pageUrl="https://qmclouds.com/devitation-reporting"
      lang={locale}
    />

    <DeviationReportingArticleHero />
    <DeviationReporting />
  </Layout>
);

export default withI18next({ ns: 'common' })(DeviationReportingPage);
