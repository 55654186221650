import { Link } from 'gatsby';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ContactRequest from '../../components/ContactRequest';
import urls from '../../components/urls';
import Asset25 from '../../images/Asset-25.svg';

export default () => {
  const { t } = useTranslation();
  return (
    <Container className="container-space article-text">
      <Row>
        <Col>
          <p>{t('DeviationReportingPara1')}</p>
          <p>{t('DeviationReportingPara2')}</p>
          <h2 className="text-blue">{t('DeviationReportingSubTitle1')}</h2>
          <p>{t('DeviationReportingPara3')}</p>
          <p>{t('DeviationReportingPara4')}</p>
          <h2 className="text-blue">{t('DeviationReportingSubTitle2')}</h2>
          <p>{t('DeviationReportingPara5')}</p>
          <h2 className="text-blue">{t('DeviationReportingSubTitle3')}</h2>
          <p>{t('DeviationReportingPara6')}</p>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col lg={6} className="mt-5">
          <ContactRequest />
        </Col>
        <Col lg={6}>
          <img
            src={Asset25}
            alt={t('ArticlePiece1Alt')}
            className="img img-w-350 block m-auto"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Link to={urls.articlePageUrl}>{t('BackToArticles')}</Link>
        </Col>
      </Row>
    </Container>
  );
};
